import OAuth from "./OAuth"
import BungieApi from "./BungieApi"
import { DestinyCharacterComponent, DestinyClass, DestinyInventoryComponent, DestinyInventoryItemDefinition, DestinyManifestSlice, DestinyProfileResponse, DictionaryComponentResponse, SingleComponentResponse } from "bungie-api-ts/destiny2"
import { CalcCharacter, CalcClassItemsDefinition as CalcCharsItemsDefinition, CalcClassItemsDefinition, CalcItemsDefinition, GearSlot, CalcItemDefinition, levelFraction } from "@/types/calculator"


const GearSlotHashes: {[key: number]: GearSlot} = {
  1498876634 : GearSlot.Kinetic,
  2465295065: GearSlot.Energy,
  953998645: GearSlot.Heavy,
  3448274439: GearSlot.Helmet,
  3551918588: GearSlot.Arms,
  14239492: GearSlot.Chest,
  20886954 : GearSlot.Legs,
  1585787867: GearSlot.Class
}

function calculateLevel(items: CalcItemsDefinition) {
  const itemsAmount = Object.keys(items).length
  const total = Object.values(items)
                  .map(item => item.level)
                  .reduce((total, level) => total + level)

  return {
    base: Math.floor(total / itemsAmount),
    fraction: (total % itemsAmount) as levelFraction
  }
}

function createCalcCharacters(classItems: CalcClassItemsDefinition, charactersDefinition: DictionaryComponentResponse<DestinyCharacterComponent>) {
  const characters: CalcCharacter[] = []
  for (const character of Object.values(charactersDefinition.data!!)) {
    characters.push({
      identity: character,
      items: classItems[character.classType],
      level: calculateLevel(classItems[character.classType])
    })
  }
  return characters
}

function getGearSlot(item: DestinyInventoryItemDefinition): GearSlot | undefined {
  if (!item.equippingBlock) return
  return GearSlotHashes[item.equippingBlock.equipmentSlotTypeHash]
}

function getProfileInventory(inventoryComponent: SingleComponentResponse<DestinyInventoryComponent>) {
    const inventory = inventoryComponent.data
    if (!inventory || Object.keys(inventory).length === 0) throw new Error("Profile inventory not retrieved")
    return inventory
}

function checkAndReplaceItem(char: CalcItemsDefinition, itemSlot: GearSlot, newItem: CalcItemDefinition) {
    if (char[itemSlot].level < newItem.level) {
      char[itemSlot] = newItem
    }
    return char[itemSlot]
}

function checkAndReplaceAllItems(chars: CalcCharsItemsDefinition, itemSlot: GearSlot, newItem: CalcItemDefinition) {
  chars[DestinyClass.Titan][itemSlot] = checkAndReplaceItem(chars[DestinyClass.Titan], itemSlot, newItem)
  chars[DestinyClass.Hunter][itemSlot] = checkAndReplaceItem(chars[DestinyClass.Hunter], itemSlot, newItem)
  chars[DestinyClass.Warlock][itemSlot] = checkAndReplaceItem(chars[DestinyClass.Warlock], itemSlot, newItem)
  return chars
}

function setHighestItems(classItems: CalcClassItemsDefinition, item: CalcItemDefinition, clazz: DestinyClass, slot: GearSlot): CalcClassItemsDefinition {
  if (clazz == DestinyClass.Unknown) {
    return checkAndReplaceAllItems(classItems, slot, item)
  } else {
    classItems[clazz][slot] = checkAndReplaceItem(classItems[clazz], slot, item)
    return classItems
  }
}

function createBasicItems(): CalcItemsDefinition {
  return {
    [GearSlot.Kinetic]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Energy]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Heavy]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Helmet]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Arms]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Chest]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Legs]: {level: 0, image: "/img/misc/missing_icon_d2.png"},
    [GearSlot.Class]: {level: 0, image: "/img/misc/missing_icon_d2.png"}
  }
}

function createClassItems(): CalcCharsItemsDefinition {
  return {
    [DestinyClass.Titan]: createBasicItems(),
    [DestinyClass.Hunter]: createBasicItems(),
    [DestinyClass.Warlock]: createBasicItems(),
    [DestinyClass.Unknown]: createBasicItems()  //Not used
  }
}

export default class HighestItems {
  static manifest: DestinyManifestSlice<["DestinyInventoryItemDefinition", "DestinyItemCategoryDefinition", "DestinyClassDefinition"]>

  public static async init() {
    if (!OAuth.isLoggedIn()) throw Error("User not logged in")
    if (!HighestItems.manifest) HighestItems.manifest = await BungieApi.getManifest("DestinyInventoryItemDefinition", "DestinyItemCategoryDefinition", "DestinyClassDefinition")
  }

  public static async getHighestItems(characters: DictionaryComponentResponse<DestinyCharacterComponent>): Promise<CalcCharacter[]> {
    await HighestItems.init()

    const profile = (await BungieApi.getItems())
    let classItems = createClassItems()
    profile.items.forEach(item => {
      const itemDef = HighestItems.manifest.DestinyInventoryItemDefinition[item.itemHash]
      const itemSlot = getGearSlot(itemDef)
      if (!itemSlot) return "item is not equipable"
      
      const itemClass = itemDef.classType
      if (!profile.instances[item.itemInstanceId!!].primaryStat) return "item doesnt have level"
      const itemLevel = profile.instances[item.itemInstanceId!!].primaryStat.value
      const newItem = {level: itemLevel, image: itemDef.displayProperties.icon}

      classItems = setHighestItems(classItems, newItem, itemClass, itemSlot)
    })

    return createCalcCharacters(classItems, characters)
  }
}