import { DestinyCharacterComponent, DestinyClass, DictionaryComponentResponse } from "bungie-api-ts/destiny2";

export type levelFraction = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
export type CalcItemDefinition = {level: number, image: string}
export enum GearSlot {
	Kinetic = "Kinetic", Energy = "Energy", Heavy = "Heavy",
	Helmet = "Helmet", Arms = "Arms", Chest = "Chest", Legs = "Legs", Class = "Class"
}
export type CalcLevelDefinition = { base: number, fraction: levelFraction }
export type CalcItemsDefinition = {[key in GearSlot]: CalcItemDefinition}
export type CalcClassItemsDefinition = {[key in DestinyClass]: CalcItemsDefinition}
export type CalcCharacter = {
	identity: DestinyCharacterComponent
	items: CalcItemsDefinition
	level: CalcLevelDefinition
}
export type CalculatorCharacters = {
	characters: CalcCharacter[]
	active: number
}