import { ComponentPrivacySetting, DestinyCharacterComponent, DictionaryComponentResponse } from "bungie-api-ts/destiny2";
import { CalcCharacter, CalculatorCharacters } from "@/types/calculator"
import { hunter, warlock, titan} from "./characters";


export const calcHunter: CalcCharacter = {
  identity: hunter,
  level: { base: 1200, fraction: 0 },
  items: {
    Kinetic: { level: 1200, image: "/common/destiny2_content/icons/f23023062214b6b778c220f3d841a4ce.jpg" },
    Energy: { level: 1200, image: "/common/destiny2_content/icons/bfd13125c9790ca30661a26b6af42b92.jpg" },
    Heavy: { level: 1200, image: "/common/destiny2_content/icons/fceef3cf93fb537e259c3734798d2946.jpg" },
    Helmet: { level: 1200, image: "/common/destiny2_content/icons/d9bf5a9b5e0f353a775ccdd4e2643795.jpg" },
    Arms: { level: 1200, image: "/common/destiny2_content/icons/a27ca0107eb05864a14b50e6b95eaf4a.jpg" },
    Chest: { level: 1200, image: "/common/destiny2_content/icons/fb4491614f02fd37a9ebda222f63e7d3.jpg" },
    Legs: { level: 1200, image: "/common/destiny2_content/icons/9b9cb8fd32ad102462ca95c59ac4a33c.jpg" },
    Class: { level: 1200, image: "/common/destiny2_content/icons/e1bbc7cdafa38d58a9a40b70db546f1e.jpg" }
  }
}

export const calcWarlock: CalcCharacter = {
  identity: warlock,
  level: { base: 1200, fraction: 0 },
  items: {
    Kinetic: { level: 1200, image: "/common/destiny2_content/icons/f23023062214b6b778c220f3d841a4ce.jpg" },
    Energy: { level: 1200, image: "/common/destiny2_content/icons/bfd13125c9790ca30661a26b6af42b92.jpg" },
    Heavy: { level: 1200, image: "/common/destiny2_content/icons/fceef3cf93fb537e259c3734798d2946.jpg" },
    Helmet: { level: 1200, image: "/common/destiny2_content/icons/98da3d1a962e9ea5bc5e3abacf29fdc7.jpg" },
    Arms: { level: 1250, image: "/common/destiny2_content/icons/8194296c8a8ac7eca4e7b36cfd4fb2ec.jpg" },
    Chest: { level: 1200, image: "/common/destiny2_content/icons/fd0b8363a9a7b4aee367e6af57577d2a.jpg" },
    Legs: { level: 1200, image: "/common/destiny2_content/icons/95236f80ab7fdd4dc9bd2557d5f57022.jpg" },
    Class: { level: 1200, image: "/common/destiny2_content/icons/f855abb5afcec223c3cd8ce065d5c5e3.jpg" }
  }
}

export const calcTitan: CalcCharacter = {
  identity: titan,
  level: { base: 1200, fraction: 0 },
  items: {
    Kinetic: { level: 1200, image: "/common/destiny2_content/icons/f23023062214b6b778c220f3d841a4ce.jpg" },
    Energy: { level: 1200, image: "/common/destiny2_content/icons/bfd13125c9790ca30661a26b6af42b92.jpg" },
    Heavy: { level: 1200, image: "/common/destiny2_content/icons/fceef3cf93fb537e259c3734798d2946.jpg" },
    Helmet: { level: 1200, image: "/common/destiny2_content/icons/771a27c3b3e3848cef192f0450f2e796.jpg" },
    Arms: { level: 1200, image: "/common/destiny2_content/icons/84858bb92bc64b4fd45207a8633d7080.jpg" },
    Chest: { level: 1200, image: "/common/destiny2_content/icons/5171070b05ef54c123438e87df762804.jpg" },
    Legs: { level: 1200, image: "/common/destiny2_content/icons/941741b73ddabc9ba40c94d3417a7d03.jpg" },
    Class: { level: 1200, image: "/common/destiny2_content/icons/ea77098ce13be03e943116326872b930.jpg" }
  }
}

const calcCharacters: CalculatorCharacters = {
  characters: [calcHunter, calcWarlock, calcTitan],
  active: 1
}

export default calcCharacters