































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LevelDisplay extends Vue {
  @Prop({required: true}) base!: number
  @Prop({required: true}) fraction!: number

  get remainingToLevelup() {
    return 8 - this.fraction
  }
}
