



























import BungieApi from "@/util/BungieApi";
import { DestinyCharacterComponent } from "bungie-api-ts/destiny2";
import { Component, Prop, Vue } from "vue-property-decorator";
import AsyncComputed from "vue-async-computed-decorator"

@Component
export default class Banner extends Vue {
  @Prop({required: true}) readonly character!: DestinyCharacterComponent
  @Prop() readonly levelBase!: DestinyCharacterComponent
  @Prop() readonly levelFraction!: DestinyCharacterComponent
  @AsyncComputed({default: {}})
  public async manifest() { return BungieApi.getManifest("DestinyClassDefinition", "DestinyRaceDefinition", "DestinyGenderDefinition") }

  // @ts-ignore
  //TODO make this check a bit nicer
  get manifestLoaded() { return this.manifest.DestinyClassDefinition }
}
