import { ComponentPrivacySetting, DestinyCharacterComponent, DictionaryComponentResponse } from "bungie-api-ts/destiny2";


export const hunter: DestinyCharacterComponent = {
  "membershipId": "4611686018428681329",
  "membershipType": 2,
  "characterId": "2305843009261892989",
  "dateLastPlayed": "2021-01-31T18:37:04Z",
  "minutesPlayedThisSession": "6",
  "minutesPlayedTotal": "90256",
  "light": 1188,
  "stats": {
      "1935470627": 1188,
      "2996146975": 33,
      "392767087": 39,
      "1943323491": 62,
      "1735777505": 66,
      "144602215": 53,
      "4244567218": 12
  },
  "raceHash": 898834093,
  "genderHash": 3111576190,
  "classHash": 671679327,
  "raceType": 2,
  "classType": 1,
  "genderType": 0,
  "emblemPath": "/common/destiny2_content/icons/5dc023c8be5d682eae90be7f5d420f69.jpg",
  "emblemBackgroundPath": "/common/destiny2_content/icons/e452c62485491a02fbc0e36f06d301d2.jpg",
  "emblemHash": 1230660645,
  "emblemColor": {
      "red": 4,
      "green": 0,
      "blue": 9,
      "alpha": 255
  },
  "levelProgression": {
      "progressionHash": 1716568313,
      "dailyProgress": 0,
      "dailyLimit": 0,
      "weeklyProgress": 0,
      "weeklyLimit": 0,
      "currentProgress": 0,
      "level": 50,
      "levelCap": 50,
      "stepIndex": 50,
      "progressToNextLevel": 0,
      "nextLevelAt": 0,
      "seasonResets": [],
      "rewardItemStates": []

  },
  "baseCharacterLevel": 50,
  "percentToNextLevel": 0.0
}

export const warlock: DestinyCharacterComponent = {
  "membershipId": "4611686018428681329",
  "membershipType": 2,
  "characterId": "2305843009273028545",
  "dateLastPlayed": "2021-01-30T16:19:16Z",
  "minutesPlayedThisSession": "1",
  "minutesPlayedTotal": "29671",
  "light": 1268,
  "stats": {
      "1935470627": 1268,
      "2996146975": 24,
      "392767087": 44,
      "1943323491": 45,
      "1735777505": 55,
      "144602215": 57,
      "4244567218": 18
  },
  "raceHash": 2803282938,
  "genderHash": 2204441813,
  "classHash": 2271682572,
  "raceType": 1,
  "classType": 2,
  "genderType": 1,
  "emblemPath": "/common/destiny2_content/icons/911791e90f955fc637398ea88aba74b7.jpg",
  "emblemBackgroundPath": "/common/destiny2_content/icons/73f5f779f40bfecb4690c395bc1941b9.jpg",
  "emblemHash": 1230660641,
  "emblemColor": {
      "red": 2,
      "green": 2,
      "blue": 2,
      "alpha": 255
  },
  "levelProgression": {
      "progressionHash": 1716568313,
      "dailyProgress": 0,
      "dailyLimit": 0,
      "weeklyProgress": 0,
      "weeklyLimit": 0,
      "currentProgress": 0,
      "level": 50,
      "levelCap": 50,
      "stepIndex": 50,
      "progressToNextLevel": 0,
      "nextLevelAt": 0,
      "seasonResets": [],
      "rewardItemStates": []
  },
  "baseCharacterLevel": 50,
  "percentToNextLevel": 0.0
}

export const titan: DestinyCharacterComponent = {
  "membershipId": "4611686018428681329",
  "membershipType": 2,
  "characterId": "2305843009261892990",
  "dateLastPlayed": "2021-01-30T16:15:12Z",
  "minutesPlayedThisSession": "37",
  "minutesPlayedTotal": "22359",
  "light": 1264,
  "stats": {
      "1935470627": 1264,
      "2996146975": 48,
      "392767087": 56,
      "1943323491": 57,
      "1735777505": 31,
      "144602215": 76,
      "4244567218": 26
  },
  "raceHash": 3887404748,
  "genderHash": 3111576190,
  "classHash": 3655393761,
  "raceType": 0,
  "classType": 0,
  "genderType": 0,
  "emblemPath": "/common/destiny2_content/icons/cb30b5a9b34f7204b064570b56576562.jpg",
  "emblemBackgroundPath": "/common/destiny2_content/icons/ecafe3e611c54e78656b85b77c8ee2f7.jpg",
  "emblemHash": 1138508277,
  "emblemColor": {
      "red": 2,
      "green": 4,
      "blue": 6,
      "alpha": 255
  },
  "levelProgression": {
      "progressionHash": 1716568313,
      "dailyProgress": 0,
      "dailyLimit": 0,
      "weeklyProgress": 0,
      "weeklyLimit": 0,
      "currentProgress": 0,
      "level": 50,
      "levelCap": 50,
      "stepIndex": 50,
      "progressToNextLevel": 0,
      "nextLevelAt": 0,
      "seasonResets": [],
      "rewardItemStates": []
  },
  "baseCharacterLevel": 50,
  "percentToNextLevel": 0.0
}

const profileCharacters: DictionaryComponentResponse<DestinyCharacterComponent> = {
  data: { "1": hunter, "2": warlock, "3": titan },
  privacy: ComponentPrivacySetting.Public
}

export default profileCharacters