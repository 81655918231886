




































import { Component, Vue } from "vue-property-decorator";
import { CalcCharacter, GearSlot, CalcItemDefinition, levelFraction} from "@/types/calculator"
import mockCalcCharacters from "@/data/mock/calculatorCharacters";
import OAuth from "@/util/OAuth";
import BungieApi from "@/util/BungieApi";
import HighestItems from "@/util/HighestItems";

import Banner from "@/components/destiny/banner.vue";
import ItemLevelDisplay from "@/components/calculator/itemLevelDisplay.vue";
import LevelDisplay from "@/components/calculator/levelDisplay.vue";


@Component({
	components: { Banner, LevelDisplay, ItemLevelDisplay }
})
export default class Leveler extends Vue {
	loggedIn = false
	chars: CalcCharacter[]
	currentCharacter = mockCalcCharacters.active

	weaponSlots = [GearSlot.Kinetic, GearSlot.Energy, GearSlot.Heavy]
	armorSlots = [GearSlot.Helmet, GearSlot.Arms, GearSlot.Chest, GearSlot.Legs, GearSlot.Class]

	get currentChar() {
		return this.$data.chars[this.$data.currentCharacter]
	}

	get currentItems() {
		return this.chars[this.currentCharacter].items
	}

	get currentBase() {
		return this.chars[this.currentCharacter].level.base
	}

	set currentBase(value: number) {
		this.chars[this.currentCharacter].level.base = value
	}

	get currentFraction() {
		return this.chars[this.currentCharacter].level.fraction
	}
	set currentFraction(value: levelFraction) {
		this.chars[this.currentCharacter].level.fraction = value
	}

	get isLoggedIn() { return this.loggedIn }
	set isLoggedIn(state: boolean) {
		this.loggedIn = state
		if (state) this.syncItems()
	}

	constructor() {
		super()
		this.chars = this.$store.getters.calcCharacters || mockCalcCharacters.characters
		// this.characters = mockCharacters
	}

	async mounted() {
		this.$store.commit('setRoute', this.$route.path);
		this.$emit('ChangeBackground', 'about');
		this.currentCharacter = this.$store.getters.calcActiveCharacter
		this.chars = this.$store.getters.calcCharacter || this.chars
		if (OAuth.isLoggedIn()) { this.loggedIn = true; this.syncItems() }
		OAuth.onLogin(() => {this.isLoggedIn = true})
		OAuth.onLogout(() => {this.isLoggedIn = false})
	}

	async syncItems() {

		await this.spinSync(async () => {
			const characters = (await BungieApi.getProfile([200])).Response.characters
			if (characters == null) throw new Error("Characters were not retrieved")
			this.chars = await HighestItems.getHighestItems(characters)
		})
	}

	async spinSync(lambda: () => void) {
		//@ts-ignore
		this.$refs.calcSync.classList.add("fa-spin")
		try { await lambda() }
		finally {
			//@ts-ignore
			this.$refs.calcSync.classList.remove("fa-spin")
		}
	}

	calculateCurrentLevel(value: {[index: string]: CalcItemDefinition}) {
		const itemsAmount = Object.keys(value).length
		const total = Object.values(value)
										.map(item => item.level)
										.reduce((total, level) => total + level)

		this.currentBase = Math.floor(total / itemsAmount)
		this.currentFraction = (total % itemsAmount) as levelFraction
		this.saveCurrentCharacters()
	}

	changeCharacter(index: number): void {
		this.currentCharacter = index
		this.$store.commit('setCalcActiveCharacter', index);
	}

	updateItemLevel(event: {name: GearSlot, level: number}) {
		// @ts-ignore
		if (this.currentItems[event.name] == null) this.currentItems[event.name] = {}
		this.currentItems[event.name].level = Number(event.level)
		this.calculateCurrentLevel(this.currentItems)
	}

	saveCurrentCharacters() {
		this.$store.commit('setCalcCharacters', this.chars)
	}
}
