










import { Vue, Component, Prop } from 'vue-property-decorator'


@Component
export default class ItemLevelDisplay extends Vue {
  @Prop({required: true}) itemDisplaySrc!: string
  @Prop({required: true}) name!: string
  @Prop({required: true}) level!: number
  @Prop({default: false}) reverse!: boolean

  urlPrefix = "https://bungie.net"

  mounted() {
    this.$emit("level", {name: this.name, level: this.level})
  }

  emitInputChange(e: Event) {
    const target = e.target as HTMLInputElement
    this.$emit("level", {name: this.name, level: target.value})
  }
}
